import React, { useRef, useEffect, useState } from "react";

const PlayableVideoComponent = ({ source }) => {
  const videoRef = useRef(null);
  const containerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          setIsVisible(entry.isIntersecting);

          // Add check for iOS to handle autoplay differently
          const isIOS =
            /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

          if (entry.isIntersecting && entry.intersectionRatio >= 0.5) {
            const playPromise = videoRef.current?.play();

            // Handle potential autoplay restrictions
            if (playPromise !== undefined) {
              playPromise.catch((error) => {
                console.error("Autoplay failed:", error);

                // For iOS, try to play with user interaction
                if (isIOS) {
                  // Add a click listener to enable playback
                  const handleUserInteraction = () => {
                    videoRef.current?.play().catch(console.error);
                    // Remove the event listener after first interaction
                    document.removeEventListener(
                      "touchstart",
                      handleUserInteraction
                    );
                  };

                  document.addEventListener(
                    "touchstart",
                    handleUserInteraction
                  );
                }
              });
            }
          } else {
            videoRef.current?.pause();
          }
        });
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: [0, 0.5, 1.0], // Observe at 50% visibility
      }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      // Remove download option
      video.setAttribute("controlsList", "nodownload");

      // Force show speed control outside menu
      video.addEventListener("loadedmetadata", () => {
        const controls = video.controls;
        if (controls) {
          video.playbackRate = 1.0;
        }
      });

      // Attempt to mute video to improve autoplay chances on iOS
      video.muted = true;
    }
  }, []);

  return (
    <div ref={containerRef} className="videoContainer">
      <video
        ref={videoRef}
        controls
        playsInline
        width="100%"
        className="videoBox visible custom-controls"
        // Add muted attribute in HTML as well
      >
        <source src={source} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default PlayableVideoComponent;
