import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Lottie from "lottie-react";

const LottieIcon = (props) => {
  const ref = useRef();

  useEffect(() => {
    if (props.play) {
      ref.current?.play();
    } else {
      ref.current?.pause();
    }
  }, [props.play]);

  return (
    <Lottie
      className={props?.className}
      lottieRef={ref}
      autoplay={props.autoplay}
      loop={props.loop}
      alt={props.alt}
      animationData={props.animationData}
      style={props.style}
      onMouseEnter={() => {
        if (props.hover) ref.current?.play();
      }}
      onMouseLeave={() => {
        if (props.hover) ref.current?.pause();
      }}
    />
  );
};

LottieIcon.propTypes = {
  autoplay: PropTypes.bool,
  loop: PropTypes.bool,
  style: PropTypes.object,
  animationData: PropTypes.object,
  hover: PropTypes.bool,
  play: PropTypes.bool,
  className: PropTypes.string,
  alt: PropTypes.string,
};

export default LottieIcon;
